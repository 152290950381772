import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationRoutingModule } from './information-routing.module';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CKEditorModule } from 'ckeditor4-angular';
import { MainComponent } from './main/main.component';
import { BoardcastComponent } from './boardcast/boardcast.component';
import { DataBankComponent } from './data-bank/data-bank.component';
import { NoteComponent } from './note/note.component';
import { NewsComponent } from './boardcast/news/news.component';
import { NewsTypeComponent } from './boardcast/news-type/news-type.component';
import { DataBankListComponent } from './data-bank/data-bank-list/data-bank-list.component';
import { DataBankTypeComponent } from './data-bank/data-bank-type/data-bank-type.component';
import { NoteListComponent } from './note/note-list/note-list.component';
import { NoteTypeComponent } from './note/note-type/note-type.component';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [
    MainComponent,
    BoardcastComponent,
    DataBankComponent,
    NoteComponent, NewsComponent, NewsTypeComponent, DataBankListComponent, DataBankTypeComponent, NoteListComponent, NoteTypeComponent
  ],
  imports: [
    CommonModule,
    InformationRoutingModule,
    FormsModule,
    CommonModule,
    ComboBoxModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    SweetAlert2Module,
    DateInputsModule,
    CKEditorModule,
    SharedModule
  ],
  exports:[NewsComponent],
  providers: [DatePipe]
})
export class InformationModule { }
